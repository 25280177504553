#container-result{
	table{
		[class^="vectoricon-"], [class*=" vectoricon-"]{margin-right: 25px;}
		&.table > thead > tr > th{border-bottom-width: 1px;}
		&.table-type-details{border: 1px solid #bbb;
			td, th{
				&:nth-child(n+2){text-align: right;}
			}
		}
	}
	.table .table {background: 0;}
	#results-general{
		table{
			@media @xs{display:block;
				tbody{display:block;}
				tr{display:block;padding:7px 0;border-bottom: 1px solid @borderColor;}
				td, th{display:block;border:0;padding:0;}
			}
			td, th{width:50%;}
		}
	}
	#results-types{
		&>div>table:not(.table-type-details)>tbody>tr{
			&>th{padding-left: 0;width:50%;}
			&>td{padding-right: 0;width:50%;}
			@media @xs{
				&>th{padding-left: 0;}
				&>td{padding-right: 0;}
			}
		}
		.table-type-details{.tableMobileNotTestPage();}
	}
	#results-questions{
		@media @xs{display:none;}
		.table-results-questions{border:1px solid #999;
			&>tbody{
				&>tr:nth-child(even) td{background:#f9f9f9;
					.table-results-questions{border:1px solid #999;
						&>tbody{
							&>tr:nth-child(odd) td{background:#e1e1e1;}
							&>tr:nth-child(even) td{background:#d5d5d5;}
							&>tr:nth-child(odd).main-question td{background: #bbb;color:#000;border-top:0;}
						}
					}
				}
				&>tr:nth-child(odd) td{
					.table-results-questions{border:1px solid #999;
						&>tbody{
							&>tr:nth-child(odd) td{background:#ddd;}
							&>tr:nth-child(even) td{background:#eee;}
							&>tr:nth-child(odd).main-question td{background: #ccc;color:#000;border-top:0;}
						}
					}
				}
			}

			&>thead{font-size:18px;
				th{background:#ddd;}
				th.question{width:40%;}
				th.answer{width:60%;}
			}

			.question-img{border:1px solid @borderColor;position: absolute;background:#fff;
				@media @lg{width:@resultImgWidthLg;}
				@media @md{width:@resultImgWidthMd;}
				@media @sm{width:@resultImgWidthSm;}
				img{
					@media @lg{max-height:@resultImgWidthLg;}
					@media @md{max-height:@resultImgWidthMd;}
					@media @sm{max-height:@resultImgWidthSm;}
				}
			}
			.question-img+.question-text{
				@media @lg{padding-left:@resultImgWidthLg + 15;}
				@media @md{padding-left:@resultImgWidthMd + 15;}
				@media @sm{padding-left:@resultImgWidthSm + 15;}
			}
			.table:not(.table-results-questions){
				td, th{
					&:first-child{
						@media @lg{width:70%;}
						@media @md{width:65%;}
						@media @sm{width:70%;}
					}
				}
			}
			.table-results-questions > tbody > tr td .table:not(.table-results-questions){
				th{border-bottom-color:#bbb;}
				td{border-top-color:#bbb;}
			}
			.table-answers-has-images{
				tbody>tr>td:first-child{
				@media @lg{padding-left:@resultAnswerImgWidthLg + 15;}
				@media @md{padding-left:@resultAnswerImgWidthMd + 15;}
				@media @sm{padding-left:@resultAnswerImgWidthSm + 15;}
			}
				td{
					@media @lg{height:@resultAnswerImgWidthLg + 16px;}
					@media @md{height:@resultAnswerImgWidthMd + 16px;}
					@media @sm{height:@resultAnswerImgWidthSm + 16px;}
				}
				.answer-img{border:1px solid @borderColor;position: absolute;background:#fff;
					@media @lg{width:@resultAnswerImgWidthLg;margin-left:-(@resultAnswerImgWidthLg + 15);}
					@media @md{width:@resultAnswerImgWidthMd;margin-left:-(@resultAnswerImgWidthMd + 15);}
					@media @sm{width:@resultAnswerImgWidthSm;margin-left:-(@resultAnswerImgWidthSm + 15);}
					img{
						@media @lg{max-height:@resultAnswerImgWidthLg;}
						@media @md{max-height:@resultAnswerImgWidthMd;}
						@media @sm{max-height:@resultAnswerImgWidthSm;}
					}
				}
			}
		}
		
		
	}
}
