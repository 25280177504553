
.unstyled(){list-style:none;margin:0;padding:0;}
.unstyledFloat(){.unstyled;
	li{margin:0;padding:0;float:left;}
}
.fontNormal(){font-family:Tahoma, Geneva, sans-serif;}
.placeholder(){color:#ccc;font-style: italic;opacity: 1;}
.mobileNavHover(){z-index:1001;
		&:hover{
			&:before{content: "Spørgsmål " attr(data-question-no) " ud af " attr(data-question-all-no);position:absolute;top:30px;right:-35px;display:block;background: #333;color:#FFF;padding:3px 10px;line-height:1.3;font-size:12px;white-space: nowrap;border-radius: @border-radius-base;}
			&:after{content:"";width: 0;height: 0;border-left: 5px solid transparent;border-right: 5px solid transparent;border-bottom: 5px solid #333;display:block;position:absolute;top:25px;left:50%;margin-left:-5px;}
		}

}
.imageZoom(){background:rgba(255,255,255,0.7);text-align: center;color:#999;border:5px solid #fff;border-width: 0 0 5px 5px;cursor:pointer;position:absolute;right:0;top:0;}

 .playIcon(){content: "\e60c";position: absolute;width: 100%;display: block;top:50%;left:0;text-align: center;color:#fff;.textShadowAllSides(1px; 9px; #999);}

.videoIconPlay(@fontSizeLg, @fontSizeMd, @fontSizeSm, @fontSizeXs){.vectoricon;.vectoricon-camera2;
	&:after{.playIcon();font-size:@fontSizeLg;margin-top:-(@fontSizeLg /2);
		@media @md{font-size:@fontSizeMd;margin-top:-(@fontSizeMd /2);}
		@media @sm{font-size:@fontSizeSm;margin-top:-(@fontSizeSm /2);}
		@media @xs{font-size:@fontSizeXs;margin-top:-(@fontSizeXs /2);}
	}
}



 // Button sizes
.textShadowAllSides(@width; @blur; @color) {
	text-shadow: @width @width @blur  @color, -@width -@width @blur  @color, -@width @width @blur  @color, @width -@width @blur  @color;
}

.tableMobile(){
	@media @mobileNav{
		display:block;font-size:13px;
		tr, td, th, tbody{display:block;}
		&>tbody>tr{border-top: 1px solid #dddddd;padding-top:@tableMobileRowPadding;overflow:hidden;
			&>td, &>th{border:0;padding:0 0 @tableMobileRowPadding ;
				&:before{content: attr(data-label) ": ";float:left;clear:left;font-weight:bold;font-size:11px;text-align:right;width:90px;padding-right:5px;line-height:20px;color:#999;}
			}
		}
		thead{position: absolute;top: -9999px;left: -9999px;
		}
	}
	@media @sm{font-size:14px;
		&>tbody>tr{padding-top:(@tableMobileRowPadding - 2);
			&>td, &>th{padding: (( @tableMobileRowPadding - 2 ) / 2) 0;
				&:before{font-size:14px;width:125px;padding-right:10px;}
			}
		}
	}
}

.tableMobileNotTestPage(){
	@media @xs{
		display:block;
		tr, td, th, tbody{display:block;}
		&>tbody>tr{border-top: 1px solid #dddddd;padding-top:@tableMobileRowPadding;overflow:hidden;
			&>td, &>th{border:0;padding:0 0 @tableMobileRowPadding ;text-align:left;
				&:before{content: attr(data-label) ": ";float:left;clear:left;font-weight:bold;font-size:13px;width:125px;padding-left:10px;padding-right:5px;line-height:20px;color:#999;}
			}
		}
		thead{position: absolute;top: -9999px;left: -9999px;
		}
	}
}

@tableMobileRowPadding:9px;
@borderColor:#dddddd;

@navSize:30px;
@navMargin:5px;
@navSizeMobile: @navSize - 4;
@navMarginMobile: @navMargin - 1;

@navBrowsePadding:13px;
@navBrowsePaddingSmall: ( @navBrowsePadding - 2 );
@navBrowsePaddingLarge: ( @navBrowsePadding + 2 );

@navBrowsePaddingMobile:6px;
@navBrowsePaddingSmallMobile: ( @navBrowsePaddingMobile - 2 );
@navBrowsePaddingLargeMobile: ( @navBrowsePaddingMobile + 2 );

@burgerHeight:16px;
@burgerWidth:18px;
@borderBorderWidth:4px;

@xsFixedTopHeight:33px;
@maxHeightBreakPoint:700px;
@maxHeightBreakPointBelow: (@maxHeightBreakPoint - 1);

@lg: ~"screen and (min-width: @{screen-lg-min})";
@md: ~"screen and (min-width: @{screen-md-min}) and (max-width: @{screen-md-max})";
@sm: ~"screen and (min-width: @{screen-sm-min}) and (max-width: @{screen-sm-max})";
@xs:  ~"print, (max-width: @{screen-xs-max})";
@max500:  ~"print, (max-width: 500px)";
@mobileNav: ~"print, (max-height: @{maxHeightBreakPointBelow}), (max-width: @{screen-xs-max})"; 
@nh: ( 1 + @screen-sm-min );
@normalNav:~"screen and (min-height: @{maxHeightBreakPoint}) and (min-width: @{screen-sm-min})";
@specialFooter: ~"print, (max-height: @{maxHeightBreakPointBelow})and (min-width: @{screen-lg-min})";

@tableBtnPaddingSides:8px;
@tableBtnBorderPadding:4px;

@resultImgWidthLg: 110px;
@resultAnswerImgWidthLg: 60px;
@resultImgWidthMd: 90px;
@resultAnswerImgWidthMd: 50px;
@resultImgWidthSm: 60px;
@resultAnswerImgWidthSm: 40px;
