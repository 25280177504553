/* Bootstrap
********************************************************************/
// Core variables and mixins
@import "../vendor/bower/bootstrap/less/variables.less"; // Modify this for custom colors, font-sizes, etc
@import "../vendor/bower/bootstrap/less/mixins.less";


// Reset and dependencies
@import "../vendor/bower/bootstrap/less/normalize.less";
//@import "../vendor/bower/bootstrap/less/print.less";
//@import "../vendor/bower/bootstrap/less/glyphicons.less";


// Grid system and page structure
@import "../vendor/bower/bootstrap/less/scaffolding.less";
@import "../vendor/bower/bootstrap/less/grid.less";


// Base CSS
//@import "../vendor/bower/bootstrap/less/type.less";
//@import "../vendor/bower/bootstrap/less/code.less";
//@import "../vendor/bower/bootstrap/less/forms.less";
@import "../vendor/bower/bootstrap/less/tables.less";

// Components: common
//@import "../vendor/bower/bootstrap/less/dropdowns.less";
//@import "../vendor/bower/bootstrap/less/component-animations.less";
//@import "../vendor/bower/bootstrap/less/close.less";

// Components: Buttons & Alerts
@import "../vendor/bower/bootstrap/less/buttons.less";
//@import "../vendor/bower/bootstrap/less/button-groups.less";
//@import "../vendor/bower/bootstrap/less/alerts.less"; // Note: alerts share common CSS with buttons and thus have styles in buttons.less

// Components: Nav
@import "../vendor/bower/bootstrap/less/navs.less";
//@import "../vendor/bower/bootstrap/less/navbar.less";
//@import "../vendor/bower/bootstrap/less/breadcrumbs.less";
//@import "../vendor/bower/bootstrap/less/pagination.less";
//@import "../vendor/bower/bootstrap/less/pager.less";

// Components: Popovers
//@import "../vendor/bower/bootstrap/less/modals.less";
//@import "../vendor/bower/bootstrap/less/tooltip.less";
//@import "../vendor/bower/bootstrap/less/popovers.less";

// Components: Misc
//@import "../vendor/bower/bootstrap/less/thumbnails.less";
//@import "../vendor/bower/bootstrap/less/media.less";
//@import "../vendor/bower/bootstrap/less/progress-bars.less";
//@import "../vendor/bower/bootstrap/less/carousel.less";

// Utility classes
//@import "../vendor/bower/bootstrap/less/utilities.less"; // Has to be last to override when necessary
//@import "../vendor/bower/bootstrap/less/responsive-utilities.less"; // Has to be last to override when necessary


/* Components
********************************************************************/
// Google Maps
//@import "components/map.less";

@import "components/owl-carousel-custom.less";
@import "../vendor/bower/blueimp/css/blueimp-gallery.css";
@import "../vendor/bower/blueimp/css/blueimp-gallery-indicator.css";
@import "base/fonts.less";
@import "settings/project-variables-and-mixins.less";
@import "components/blueimp-custom.less";
@import "base/test.less";
@import "base/login.less";
@import "base/list-page.less";
@import "views/results.less";
@import "base/site.less";

