
#container-list{
	.photo-participant{
		img{border-radius: 4px;overflow: hidden;border:1px solid #ccc;
			@media @lg{max-height: 213px;}
			@media @md{max-height: 176px;}
			@media @sm{max-height: 211px;}
			@media @xs{max-height: 211px;}
		}
	}
}

.table-test-list{
	[class^="icon-"], [class*=" icon-"]{}
	.list-timer{background:@btn-warning-bg;padding: 0px 9px;font-weight:bold;line-height: 22px;display: inline-block;}
	button.btn-result{border: 0;background-color: transparent;padding: 0 22px 0 @tableBtnPaddingSides;margin-left: 10px;position: relative;border:0;color:@btn-default-color;z-index:2;
		@media @xs{padding: 0 ((@tableBtnPaddingSides / 2 ) + 12 ) 0 (@tableBtnPaddingSides / 2 );left: 5px;}
		&:hover{text-decoration: none;
			&:before{background-color: darken(@btn-default-bg, 12%);background-position: 0 -15px;}
			}
		&:before{content:"";z-index:-1;border:1px solid @btn-default-border;position: absolute;top:0;left:0;width:100%;height:100%;padding:@tableBtnBorderPadding @tableBtnBorderPadding (@tableBtnBorderPadding - 2);box-sizing: content-box;margin-top:-@tableBtnBorderPadding;margin-left:-@tableBtnBorderPadding;border-radius: 4px;}
		i{font-size:8px;position: absolute;top:50%;margin-top:-3px;right:@tableBtnPaddingSides;
			@media @xs{right:(@tableBtnPaddingSides / 2 );}
		}
	}	
	.tableMobile();
	.activation-code{padding: 3px 5px 2px;max-width: 130px;position: relative;top: -4px;
		@media @xs{top: 0;}
	}
	.btn-activation{padding: 2px 8px; position: relative; top: -6px; margin-left: 5px; 
		@media @xs{top: -2px;}
	}
}

