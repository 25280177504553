//Overwriting some styles from the native blueimp stylesheet
.blueimp-gallery{background:rgba(0, 0, 0, 0.7);
	&>.title, &>.title:hover{background: #fff;width: 90%;padding: 9px 67px 9px 15px;color: #000;text-shadow: none;font-weight: normal;font-size: 14px;line-height: 1.3;display:block;width: 100%;left: 0;top: 0;min-height: 38px;opacity: 0.95;
		@media @xs{font-size:12px;min-height:31px;}
		&.less-shown, &.more-shown{padding-right:185px;
			@media @xs{padding-right:45px;}
			&:before{content:"Se hele teksten";display: block;position: absolute;background: @btn-info-bg;color:@btn-info-color;border:1px solid @btn-info-border;border-radius: @border-radius-base;width:110px;padding:4px 0;text-align: center;top:4px;right:60px;cursor: pointer;
				@media @xs{right:45px;content:"Mere...";width:60px;float:right;position:static;margin-top:-4px;margin-left: 10px;}
			}
		}
		&.less-shown{white-space: nowrap;overflow: hidden;text-overflow: ellipsis;
			@media @xs{padding-right:45px;}
		}
		&.more-shown{
			&:before{content:"Vis mindre tekst";
				@media @xs{content:"Mindre"}
			}
		}
	}
 	& > .slides{max-height: 100vh;padding-top:38px;padding-bottom:61px;
 		@media @xs{padding-top:33px;padding-bottom:48px;}
 		& > .slide-loading {background: 0;/*here was a loading gif*/}
 	}
 	& > .play-pause {background-image:none;background: 0;/*here was a play/pause icon*/}
 	&> .prev, & > .next{top:auto;bottom:15px;
 		@media @xs{width:30px;height:30px;font-size: 50px;line-height: 22px;@media @xs{bottom: 10px;}}
 	}
 	&>.indicator{
 		@media @xs{bottom: 10px;}
 		&> li{background-color:#fff;background-repeat: no-repeat;background-size: cover;width:30px;height:30px;border-radius: 30px;
	 		@media @xs{width:10px;height:10px;border-radius: 10px;background:#fff;background-image: none !important;opacity: 1;
	 			&.active{background: @btn-info-bg;}
	 		}
	 	}
 	}
 	&>.close{top:1px;background-color:#d9534f;height:52px;display: block;opacity: 1;
 		@media @xs{padding:15px 10px;height:47px;padding-top:12px;}
 		&:hover{background-color:#666;}
 	}
 	&>.play-pause{display: none;}
 }
 body:last-child .blueimp-gallery > .play-pause {background-image:none;background: 0;/*here was a play/pause icon*/}
#links{position:absolute;left:-3333px;width:20px;overflow:hidden;} 
