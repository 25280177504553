/*reset*/
:fullscreen a {
    transition: transform 1s;
}

html, body{height: 100%}

@-ms-viewport {
  width: auto;
}
.hidden{display:none;}
.row:before, .row:after {content: " ";display: table;}
.row:after {clear: both;}
a:focus{text-decoration: none;}
body:after {visibility:hidden;position:fixed;speak:none;
    @media @lg {& { content: "media-lg"; }}
    @media @md {& { content: "media-md"; }}
    @media @sm {& { content: "media-sm"; }}
    @media @xs {& { content: "media-xs"; }}
}
a, a:focus, button, button:focus, input, input:focus{outline:none;}

/*end reset*/
body, input, select, option, textarea{.fontNormal()}
audio{display:none;}
body{margin:0;padding:0;background:#f5f5f5;
	@media @mobileNav{padding-top:50px;background:#fff;
		&.page-in-navigation{padding-bottom:@xsFixedTopHeight;}
	}
}

input[type="email"], input[type="number"], input[type="password"], input[type="tel"], input[type="url"], input[type="text"], textarea{border:1px solid #b0b0b0;}

.img-super-responsive{width:auto;height:auto;max-width:100%;display: block;margin:0 auto;}


.container{background:#fff;
	@media @lg{padding:0 @grid-gutter-width (@grid-gutter-width / 2);}
}
.container-header{
	@media @mobileNav{padding-bottom:0;}
	#header{clear:both;overflow: hidden;padding: 0 0 (@grid-gutter-width/2);
		@media @lg{width: @container-lg - @grid-gutter-width;margin-left:-15px;padding-left: 15px;padding-right: 15px;}
		@media @md{width: @container-md - @grid-gutter-width;}
		@media @sm{width: @container-sm - @grid-gutter-width;}
		@media @xs{width: 100%;}
		@media @mobileNav{position: fixed;top: 0;z-index: 888888;min-width: 270px;height:@xsFixedTopHeight;overflow: visible; }
		.logo-holder{
			@media @mobileNav{display: none;}
			figure{width: 250px;height:50px;display: table-cell;vertical-align: middle;text-align: left;
				@media @mobileNav{width: 170px;height:30px;}
			}
			img{max-height:40px;max-width:250px;margin:0;
				@media @mobileNav{max-width: 170px;max-height:25px;}
			}
		}
		.user-holder{@media @mobileNav{width:100%;}}
		.timer{float: right;margin: 0 0;background:  @btn-info-bg; color:@btn-info-color;border:1px solid @btn-info-border;border-width:0 1px 1px;height:50px;padding:0 15px;border-radius: 0 0 0 4px;
			@media @mobileNav{top:0;left:34px;height:@xsFixedTopHeight;border-radius: 0;padding:0 10px;z-index:1002;/********/position: absolute;}
			@media @xs{border-radius: 0;}
			&.end-is-near{background:  @btn-danger-bg; color:@btn-danger-color;border:1px solid @btn-danger-border;}		
			span{line-height:48px;
				@media @mobileNav{line-height: (@xsFixedTopHeight - 2);}
				@media @xs{line-height:30px}
				span{@media @xs{display: none;}}
			}
		}
		.user-info{float:right;text-align: right;color:#999;background: #f7f7f7;border: 1px solid #ccc;border-width:0 1px 1px 0;margin:0 0 0;padding:4px 15px;height:50px;border-radius: 0  0 4px 0;
			@media @mobileNav{display: none;}
			@media @xs{display: none;}
		}
		#test-part-finished{.mobileNavHover();display: block;position: absolute;right: 12px;top:3px;font-size:12px;border:1px solid @borderColor;width: 40px;text-align:center;z-index:1001;
			@media @xs{right:45px;}
			@media @normalNav{display: none;}
			b{display: block;height: 7px;border-top: 1px solid @borderColor;position: relative;background:#eee;
				i{display: block;position: absolute;left:0;height:100%;background:#ccc;}
			}
			&:hover{&:before{content: "Du har besvaret " attr(data-percent) "% af prøvens spørgsmål";width:160px;white-space: normal;right:0;}}
		}
		#current-question{.mobileNavHover();display: block;position: absolute;right: 65px;top:0px;line-height: @xsFixedTopHeight;
			@media @xs{right:98px;}
			@media @normalNav{display: none;}
		}
	}
	.mobile-logo {display: none;
		@media @mobileNav{display: block;padding: 20px 0;width: 100%;
			img{margin: 0;}
		}
	}
	&.container-header-non-question{
		#header{
			.user-info{border-radius: 0 0 4px 4px;border-width: 0 1px 1px 1px;}
		}
		@media @mobileNav{
			#header{
				.user-info{display:block;position: fixed;padding:11px 0 0;font-size:12px;height: auto;border:0;background: 0;line-height: 1.3;text-align: left;color:#888;}
			}
		}
	}
}
		#mobile-nav-trigger{display: block;cursor: pointer;border:3px solid @btn-info-bg;border-width: @borderBorderWidth 0;position: absolute;z-index:1001;top: 0;left: 0;width: @xsFixedTopHeight;height: @xsFixedTopHeight;border:0;	
			@media @normalNav{display: none;}
			&:before{content:"";position: absolute;width: @burgerWidth;height:@burgerHeight;border:3px solid @btn-info-bg;border-width: @borderBorderWidth 0;margin:0;top: ( @xsFixedTopHeight - @burgerHeight ) / 2;left:50%;margin-left:-( @burgerWidth / 2);}
			&:after{content:"";position: absolute;width: @burgerWidth;height:@borderBorderWidth;background:@btn-info-bg;margin:0;top: ( ( @xsFixedTopHeight - @burgerHeight ) / 2) + 6;left:50%;margin-left:-( @burgerWidth / 2);}
			.menu-text {position: absolute; bottom: -15px; font-size: 10px; text-transform: uppercase; color: #5BC0DE; left: 3px;}
		}
#mobile-header-bg{display: none;height:50px;position:fixed;top:0;left:0;width:100%;background:#ddd;z-index:1000;
	@media @mobileNav{display: block;} 
} 
 
.row-nav{position:relative;overflow: hidden;
	#nav-test{#gradient.vertical-three-colors(rgba(252,252,252,0); rgba(252,252,252,0); 60%; rgba(252,252,252,1));margin:0 0 (@grid-gutter-width /2);border-bottom: 1px solid #f1f1f1;
		@media @lg{margin:0 0 (@grid-gutter-width);}
		@media @mobileNav{max-height: 0;overflow: hidden;padding-top: 0;padding-bottom: 0;}
		ul{.unstyledFloat;overflow: hidden;padding:5px 0px;
			@media @lg{&.has-widow-lg li:last-child{float:right;}}
			li.user-info{display:none;clear:both;width: 100%;position: relative;margin:10px 0 0;padding:5px 0 0;font-size: 13px;color: #999;
				@media @mobileNav{display: block;}
				&:before, &:after{content:"";display: block;position: absolute;width: 100%;height: 0px;border-top:1px solid #ccc;top:0;margin-left:-10px;}
				&:after{margin-left:10px;}
			}
			&.pagination-only-user-info{
				li.user-info{display:none;margin:0;padding:0;
					@media @mobileNav{display: block;}
					&:before, &:after{content:"";display: none;}
				}
			}
		}
		a{background:#fff;border: 1px solid @borderColor;margin:0 @navMargin @navMargin 0; width:@navSize;height:@navSize;line-height:(@navSize - 2px);border-radius: @navSize;display:block;text-align: center;

			&.text{width:@navSize * 4 + 3 * @navMargin;background:#f1f1f1;
				&:hover{border-color: #ccc;}
			}
			&.end-test{width:@navSize * 3 + 2 * @navMargin;background:  @btn-warning-bg; color:@btn-warning-color;border:1px solid @btn-warning-border;
				&:hover{border-color: darken(@btn-warning-border, 15%);}
			}
			&.done{background:#D8E8B1;color:#3a7c90;border-color: #aaa;border-top-right-radius: 2px;position: relative;.vectoricon-checkmark;
				&:before {.vectoricon;position:absolute;color:#aaa;font-size:9px;top:0;right:1px;
				}
			}
			&.current{background: @btn-primary-bg;color:@btn-primary-color;border: 1px solid @btn-primary-border;border-radius: 2px;}
			&:hover{text-decoration: none;border-color:#666;}
			@media print, (max-width: 500px){ width:@navSizeMobile;height:@navSizeMobile;line-height:( @navSizeMobile - 2px );margin:0 @navMarginMobile @navMarginMobile 0;
				&.text{width: @navSizeMobile * 4 + 3 * @navMarginMobile ;}
				&.done{&:before {font-size:7px;}}
			}
		}
	}
}

#mobile-footer-bg{display: none;height:@xsFixedTopHeight;position:fixed;bottom:0;left:0;width:100%;background:#ddd;z-index:1000;margin:0;;
	@media @mobileNav{display: block;}
}
.container-footer{
	position: fixed;right:0;z-index: 88888;left:0;bottom:0;
	@media @mobileNav{background:0;height: 33px;}
	.nav-browse{margin:0 -(@grid-gutter-width/2);
		@media @mobileNav{height: @xsFixedTopHeight;position: relative;}
		@media @specialFooter{margin:0 -30px;}
		.logo-holder{
			display: none;
			@media @mobileNav{display:block; position: absolute;top: 3px;left: 40px;right: 80px;}
		}
		.footer-logo{height: 28px; margin: 0;}
		ul{.unstyled;border-top:1px solid #f1f1f1;padding: (@grid-gutter-width/2) 0;/*margin-top:@grid-gutter-width;*/overflow: hidden;#gradient.vertical-three-colors(rgba(252,252,252,1); rgba(252,252,252,0); 40%; rgba(252,252,252,0));
			@media @sm{margin-top: (@grid-gutter-width/2);padding: 10px 0;}
			@media @mobileNav{margin:0;background-image:none;padding:0;border:0;}
			li{margin:0;padding:0;
				a{display: inline-block;white-space: nowrap;position:absolute;font-size:18px;opacity: 0.7;background:#fff;border: 1px solid #dddddd;margin: 0 5px 5px 0;border-radius: 30px;display: block;text-align: center;padding:@navBrowsePadding;
					@media @sm{font-size:16px;padding:@navBrowsePadding - 3;}
					@media @mobileNav{position: absolute;bottom:0;z-index:2000;padding:0;font-size:12px;border-radius: 0;width:@xsFixedTopHeight;height:@xsFixedTopHeight;margin:0;border:0;border: 1px solid #e7e7e7;border-width: 1px 0;}
					@media @normalNav{&:hover{opacity: 1;text-decoration: none;color:@link-color;}}
					span{display: none;}
				}
				&.top{float: left;position: relative;left: 50%;
					@media @mobileNav{display: none;}
					a{.vectoricon; .vectoricon-arrow-up7;position: static;margin: 0 auto;display: block;position: relative;left: -50%;float: left;padding-top:@navBrowsePaddingSmall;padding-bottom:@navBrowsePaddingLarge;
						@media @sm{padding-top:@navBrowsePaddingSmall - 3;padding-bottom:@navBrowsePaddingLarge - 3;}
					}
				}
				&.next a{.vectoricon; .vectoricon-arrow-right7;right:(@grid-gutter-width/2);padding-right:@navBrowsePaddingSmall;padding-left:@navBrowsePaddingLarge;
					@media @sm{padding-right:@navBrowsePaddingSmall - 3;padding-left:@navBrowsePaddingLarge - 3;}
					@media @mobileNav{right:0;line-height:@xsFixedTopHeight;
						&:before{margin-left: -3px;}
					}
				}
				&.previous a{.vectoricon; .vectoricon-arrow-left7;left:(@grid-gutter-width/2);padding-left:@navBrowsePaddingSmall;padding-right:@navBrowsePaddingLarge;
					@media @sm{padding-left:@navBrowsePaddingSmall - 3;padding-right:@navBrowsePaddingLarge - 3;}
					@media @mobileNav{left:0;line-height:@xsFixedTopHeight;
						&:before{margin-left: -1px;}
					}
				}
			} 
		}
		#current-question-bottom{.mobileNavHover();display: block;right: 50%;bottom:0px;line-height: @xsFixedTopHeight;z-index: 2002;width:60px;text-align: left;margin-right: 5px;float: right;
			@media @normalNav{display: none;}
			&:hover{
				&:before{content: "Spørgsmål " attr(data-question-no) " ud af " attr(data-question-all-no);top:auto;bottom:30px;right:50%;left:auto;.translate(50%, 0);}
				&:after{border-top: 5px solid #333;border-bottom:0;top:auto;bottom:25px;}
			}   
		}
	}
}
#mobile-nav-input{position: absolute;left: -3333px;
	@media @mobileNav{
		&:checked{
			&~#mobile-nav-trigger{background:#666;}
			&~.container-navigation{background:#666;border-bottom:30px solid #fff;padding-bottom:0;
				#nav-test{margin:0;background-image:none;padding-top:15px;padding-bottom:15px;max-height: 600px;.transition(max-height 0.25s ease);border:0;
					.pagination{background:#fff;border-radius: @border-radius-base;padding: 10px 10px 5px;}
				}
			}
		}
	}
}

h1{@media @xs{font-size:20px;}}
h2{@media @xs{font-size:16px;}}


p{margin:0 0 1em;}

[class^="icon-"], [class*=" icon-"]{height:10px;position:relative;width:22px;display: inline-block;
	&:before{padding: 3px 2px 3px 3px;border-radius: 4px;position: absolute;top:-6px;}
	&.vectoricon-stopwatch{font-size: 22px;line-height: 14px;position: relative;top: 3px;}
}
.icon-success:before{background:@btn-success-bg;color:@btn-success-color;border:1px solid @btn-success-border;}
.icon-neutral:before{background:0;color:@btn-default-color;border:1px solid transparent;}
.icon-danger:before{background:@btn-danger-bg;color:@btn-danger-color;border:1px solid @btn-danger-border;}
.icon-alone:before{padding:0;border-radius:0;}
.icon-warning:before{background:@btn-warning-bg;color:@btn-warning-color;border:1px solid @btn-warning-border;}





.text-page{
	.text{
		font-size: 16px;
		&.has-audio{position: relative;padding-left: 30px;
			.audio{position: absolute;top: 3px;left:0;}
		}
		h1{margin: 0 0 1em;}
	}
	.btn-text-next{float: right;font-size: 18px;margin:0 0 15px;
		@media @xs{font-size: 16px;}
		i{font-size: 14px;
			@media @xs{font-size: 12px;}
		}
	}
	[class^="col-"], [class*=" col-"]{
		@media @max500{width: 100%}
	}

}

.logout-btn-holder{float: right; margin-right: 20px; margin-top: 10px;}

/************************ LOADING CIRCLE ****************/
.sk-fading-circle {
  margin: 100px auto;
  width: 40px;
  height: 40px;
  position: relative;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
          animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}
.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
      -ms-transform: rotate(30deg);
          transform: rotate(30deg);
}
.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
      -ms-transform: rotate(60deg);
          transform: rotate(60deg);
}
.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
      -ms-transform: rotate(90deg);
          transform: rotate(90deg);
}
.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
      -ms-transform: rotate(120deg);
          transform: rotate(120deg);
}
.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
      -ms-transform: rotate(150deg);
          transform: rotate(150deg);
}
.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
      -ms-transform: rotate(180deg);
          transform: rotate(180deg);
}
.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
      -ms-transform: rotate(210deg);
          transform: rotate(210deg);
}
.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
      -ms-transform: rotate(240deg);
          transform: rotate(240deg);
}
.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
      -ms-transform: rotate(270deg);
          transform: rotate(270deg);
}
.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
      -ms-transform: rotate(300deg);
          transform: rotate(300deg); 
}
.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
      -ms-transform: rotate(330deg);
          transform: rotate(330deg); 
}
.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
          animation-delay: -1.1s; 
}
.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
          animation-delay: -1s; 
}
.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
          animation-delay: -0.9s; 
}
.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
          animation-delay: -0.8s; 
}
.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
          animation-delay: -0.7s; 
}
.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
          animation-delay: -0.6s; 
}
.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
          animation-delay: -0.5s; 
}
.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
          animation-delay: -0.4s;
}
.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
          animation-delay: -0.3s;
}
.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
          animation-delay: -0.2s;
}
.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
          animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; }
}

@keyframes sk-circleFadeDelay {
  0%, 39%, 100% { opacity: 0; }
  40% { opacity: 1; } 
}