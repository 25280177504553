

.login-box-bg {
    text-align: center;
    background: #c0c0c0;
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;

    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -0.25em; /* Adjusts for spacing */ /* For visualization background: #808080; width: 5px;*/
    }

    .login-box {
        display: inline-block;
        vertical-align: middle;
        padding: 30px 30px;
        border: #a0a0a0 solid 1px;
        background-color: #f5f5f5;
        border-radius: 4px;
        border-top-left-radius: 0;
        border-top-right-radius: 0;

        @media @mobileNav {
            padding: 20px 30px;
        }

        .login-box__tabs {
            text-align: left;
            margin-top: -72px;
            margin-bottom: 30px;
            margin-left: -31px;
            width: ~"calc(100% + 62px)";
            background: rgb(220,220,220);
            border: #a0a0a0 solid 1px;
            border-top-left-radius: 4px;
            border-top-right-radius: 4px;

            @media @mobileNav {
                margin-top: -62px;
            }

            &--selected {
                background: #f5f5f5;
                text-decoration: underline;
                border-left: #a0a0a0 solid 1px;

                &:first-child {
                    border-left: none;
                }
            }

            a {
                padding: 10px;
                width: 50%;
                display: inline-block;
                margin-right: -5px;
                text-align: center;
                border-right: #a0a0a0 solid 1px;
                border-top-right-radius: 4px;

                &:hover {
                    cursor: pointer;
                }

                &:last-child {
                    border-right: none;
                }
            }
        }

        label {
            position: relative;
            display: block;
            clear: both;

            span {
                float: left;
                width: 100px;
                text-align: right;
                clear: left;
                margin: 0 0 15px;
                padding: 0 10px 0 0;
                line-height: 30px;

                @media @xs {
                    float: none;
                    display: block;
                    text-align: left;
                    width: auto;
                    margin: 0;
                }
            }

            &.error {
                .vectoricon-warning;

                &:before {
                    color: @btn-danger-bg;
                    .vectoricon;
                    position: absolute;
                    right: -18px;
                    top: 6px;

                    @media @xs {
                        top: auto;
                        bottom: 10px;
                    }
                }

                input {
                    border-bottom-color: @btn-danger-border;
                }
            }
        }

        input[type="password"], input[type="text"] {
            float: left;
            width: 200px;
            padding-left: 5px;
            line-height: 30px;
            height: 30px;

            @media @xs {
                float: none;
                display: block;
                margin: 0 0 10px;
                width: 220px;
            }
        }

        button.btn {
            clear: both;
            float: right;
        }

        figure {
            display: block;
            margin: 0 0 15px;

            img {
                max-width: 200px;
                max-height: 100px;
                margin: 0 0 0 auto;

                @media @xs {
                    max-width: 220px;
                }
            }
        }

        .company-logo {
            padding-bottom: 15px;
            max-width: 380px;
            margin: 0 auto;

            @media @mobileNav {
                padding-bottom: 5px;

                img {
                    max-width: 200px;
                    max-height: 60px;
                }
            }
        }

        iframe {
            width: 300px;

            @media @mobileNav {
                width: 210px !important;
                height: 200px !important;
            }
        }
    }
}

#login__text--prompt {
    width: 300px;
}

#login__function--container {
    min-height: 140px;
}

.flag {
    cursor: pointer;
    opacity: 0.5;
}

.flag:hover {
    opacity: 1;
}

.flag--selected {
    opacity: 1;
}