span.audio{display:inline-block;width:24px;height:24px;position: absolute;top:0px;.vectoricon;.vectoricon-volume-medium;font-size:24px;cursor: pointer;left:0;
	&.disabled{background-color: red;}
}
.image-zoom{.imageZoom();}
.touchevents .btn-next-question{
	display:none;
}

.test-sound{position: relative; padding-left: 40px; display: inline-block; line-height: 24px;}
.main-question{padding-bottom: 15px;}
.main-question ~ .row.question-box, .row.question-box ~ .row.question-box{border-top:1px solid @borderColor;padding-top: 15px;}
.row.question-box, .main-question{
	font-size: 16px;
	.img-right{float: right;
		@media @xs{float:none;}
	}
	.question{margin: 0 0 15px;
		&.has-audio{padding-left: 30px;position: relative;}
		span.audio{left: 0;}

	}
	ul.answers{.unstyled();padding: (@grid-gutter-width/2) 0 0;
		input[type=radio], input[type=checkbox]{margin:0;display: block;position:absolute;top: 4px;}
		li{position: relative;min-height: 20px;margin:0 0 (@grid-gutter-width/2);}
		label{padding-left: 25px;display:inline-block;}
		.file-attached{padding-left: 20px;}
		&.has-audio label{padding-left: 55px;}
		span.audio{left:25px;}
		figure{cursor:pointer;width:100px;border:1px solid @borderColor;position:absolute;height:100px;background-size: contain;background-repeat: no-repeat;background-color:#fff;background-position: center center;
			.image-zoom{border-width: 0 0 3px 3px;border-radius: 0 0 0 5px;font-size:18px;width:28px;height:27px;padding: 3px 0 0;z-index:3;}
			@media @max500{position: static;position: relative;}
			&.video{.videoIconPlay(40px,40px,40px,40px);
				&:before{.imageZoom();.image-zoom;}
			}
		}
		&.has-images{ 
			li{min-height: 100px;}
			label, .file-attached{padding-left:130px;
				@media @max500{padding-left:20px;spadding-top:100px;}
			}
			figure{margin-left:21px;}
			&.has-audio {
				figure{margin-left:60px;}
				label, .file-attached{padding-left:170px;
					@media @max500{padding-left:40px;} 
				}
			}
		}
	}
	div.answers{
		textarea{width: 100%;display: block;border-radius: @border-radius-base;height: 150px;padding: 7px 10px;overflow-y: auto;overflow-x: hidden;color:#333; 
			&:focus{outline:none;}
		} 
	}
	.question-media{position: relative;
		@media @xs{sheight: 290px;smax-width: 290px;margin:0 0 (@grid-gutter-width/2);}
		.image-zoom{width:55px;height:55px;font-size:33px;padding: 9px 0 0;border-radius:0 0 0 10px;top:0px;right:0px;z-index:3;} 
		figure{background-repeat: no-repeat; background-position:center top;background-size: contain;border:1px solid @borderColor;cursor: pointer;position: relative;
			@media @xs{max-width: 290px;}
			img{max-height:300px;
				@media @lg{max-height: 540px;}
				@media @md{max-height: 455px;}
				@media @sm{max-height: 275px;}
			}
			&.video{.videoIconPlay(126px,100px,40px,70px);
				&:before{.imageZoom();.image-zoom;}
			}
		}
	}
}
#question-id{font-size:16px;margin: @grid-gutter-width 0 0;border-top:1px solid @borderColor;float: right;color: @borderColor; 
	@media @sm{margin-top: ( @grid-gutter-width / 2 );}
} 


.file-attached{clear: both;overflow: hidden;
	a{float: left;clear: both;text-decoration: underline;position: relative;padding-left: 20px;font-size:13px;
		i{margin-right: 5px;text-decoration: none;position: absolute;left: 0;top:3px;color:#333;}
	}
}

::-webkit-input-placeholder {.placeholder();}/* WebKit browsers */
::-moz-placeholder {.placeholder();}/* Mozilla Firefox 19+ */
:-ms-input-placeholder {.placeholder()}/* Internet Explorer 10+ */

#loading-overlay {
	background-color: fade(#333, 50%);
    z-index: 999999;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: none;
    .sk-fading-circle  { position: absolute; top: 50%; margin-left: -20px; left:50%; margin-top: -20px;}
}